import React, { ReactNode } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';  
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import ROUTES from 'routes';
import { USER_TYPE } from 'queries/users';


export interface MenuItem {
  text: string;
  icon: SvgIconComponent;
  path?: string;
  adminOnly?: boolean;
  subItems?: MenuItem[];
  onClick?: () => void;
}

// Menu items available for all users in the bottom section
const baseMenuItems: MenuItem[] = [
  { text: 'My Profile', icon: PersonOutlineOutlinedIcon, path: ROUTES.MY_PROFILE },
  { text: 'Learn Center', icon: LightbulbOutlinedIcon, path: 'https://scoutlabs.ag/learn/' },
  { text: 'About', icon: InfoOutlinedIcon },
  { text: 'Logout', icon: LogoutOutlinedIcon },
];

// Menu items available by general users
const generalUserMenuItems: MenuItem[] = [
  { text: 'Home', icon: HomeOutlinedIcon, path: ROUTES.DASHBOARD },
  { text: 'Devices', icon: DevicesOutlinedIcon, path: ROUTES.DEVICES },
  {
    text: 'Trap Groups',
    icon: WorkspacesOutlinedIcon,
    path: ROUTES.ORCHARD,
    subItems: [
      { text: 'Your groups', icon: FeedOutlinedIcon, path: ROUTES.ORCHARD },
      { text: 'Manage', icon: NoteAltOutlinedIcon, path: ROUTES.ORCHARD_MANAGER },
    ],
  },
  { text: 'Map', icon: MapOutlinedIcon, path: ROUTES.MAP },
  { text: 'Export', icon: FileDownloadOutlinedIcon, path: ROUTES.EXPORT },
];

// Menu items available by validator users
const validatorMenuItems: MenuItem[] = [
  { text: 'Validator Devices', icon: FactCheckOutlinedIcon, path: ROUTES.VALIDATOR_DEVICES },
];

// Menu items available by admin users
const adminMenuItems: MenuItem[] = [
  { text: 'Home', icon: HomeOutlinedIcon, path: ROUTES.DASHBOARD },
  { text: 'Devices', icon: DevicesOutlinedIcon, path: ROUTES.DEVICES },
  { text: 'Validator Devices', icon: FactCheckOutlinedIcon, path: ROUTES.VALIDATOR_DEVICES },
  {
    text: 'Trap Groups',
    icon: WorkspacesOutlinedIcon,
    path: ROUTES.ORCHARD,
    subItems: [
      { text: 'Your groups', icon: FeedOutlinedIcon, path: ROUTES.ORCHARD },
      { text: 'Manage', icon: NoteAltOutlinedIcon, path: ROUTES.ORCHARD_MANAGER },
    ],
  },
  { text: 'Map', icon: MapOutlinedIcon, path: ROUTES.MAP },
  { text: 'Export', icon: FileDownloadOutlinedIcon, path: ROUTES.EXPORT },
  {
    text: 'Management',
    icon: ManageAccountsOutlinedIcon,
    path: ROUTES.MANAGEMENT,
    subItems: [
      { text: 'Users and Devices', icon: PeopleOutlineOutlinedIcon, path: ROUTES.MANAGEMENT },
      { text: 'Validator', icon: VerifiedUserOutlinedIcon, path: ROUTES.VALIDATOR_MANAGEMENT },
    ],
  },
  { text: 'Devices Config', icon: SettingsOutlinedIcon, path: ROUTES.DEVICES_CONFIG },
  { text: 'Firmwares', icon: BuildOutlinedIcon, path: ROUTES.FIRMWARES },
];

export const getMenuItems = (userType: USER_TYPE): MenuItem[] => {
  switch (userType) {
    case USER_TYPE.ADMIN:
      return [...adminMenuItems, ...baseMenuItems];
    case USER_TYPE.VALIDATOR:
      return [...validatorMenuItems, ...baseMenuItems];
    case USER_TYPE.REGULAR:
    case USER_TYPE.CORPORATE:
    default:
      return [...generalUserMenuItems, ...baseMenuItems];
  }
};

export const separateMenuItems = (items: MenuItem[]) => {
  const baseItemTexts = baseMenuItems.map((item: MenuItem) => item.text);
  const baseItems = items.filter((item: MenuItem) => baseItemTexts.includes(item.text));
  const mainItems = items.filter((item: MenuItem) => !baseItemTexts.includes(item.text));
  return { mainItems, baseItems };
};
