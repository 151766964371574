import React from "react";
import { ResponsivePie } from '@nivo/pie';
import { DeviceDetailedStatus, DeviceGeneralStatus, statusToReadable, getDeviceStatusColor } from "utils/device-statuses";

export interface DeviceStatusData {
  status: DeviceDetailedStatus | DeviceGeneralStatus;
  count: number;
  color: string;
}

interface Props {
  data: DeviceStatusData[];
}

const CustomTooltip = ({ datum }: { datum: any }) => (
  <div style={{
    backgroundColor: 'rgba(39, 56, 44, 0.7)',
    border: 'none',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    padding: '8px',
    color: '#ffffff',
    fontSize: '0.75rem'
  }}>
    {datum.id}: {datum.value}
  </div>
);

const DeviceStatusDonutChart: React.FC<Props> = ({ data }) => {
  const chartData = data.map(item => ({
    id: statusToReadable(item.status),
    value: item.count,
    color: item.color
  }));

  return (
    <div style={{ height: '100%', overflow: "visible", position: "relative" }}>
      <ResponsivePie
        data={chartData}
        margin={{ top: 22, right: 22, bottom: 22, left: 22 }}
        innerRadius={0.7}
        padAngle={0.6}
        colors={d => d.data.color}
        activeInnerRadiusOffset={3}
        activeOuterRadiusOffset={3}
        cornerRadius={2}
        enableArcLabels={false}
        arcLinkLabelsSkipAngle={5}
        enableArcLinkLabels
        tooltip={CustomTooltip}
        arcLinkLabel={d => {
          const total = chartData.reduce((sum, item) => sum + item.value, 0);
          const percentage = (d.value / total) * 100;
          return percentage > 1 ? `${d.id} (${percentage.toFixed(1)}%)` : '';
        }}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
      />
    </div>
  );
};

export default DeviceStatusDonutChart;
