/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";
import { ListDeviceItem } from "./devices";

export type ListCorporationParams = {
  page: number;
  page_size: number;
  search?: string;
  id__in?: string[];
};
export type ListCorporationItem = {
  id: string;
  created_at: number;
  name: string;
  devices: Pick<ListDeviceItem, "id">;
};
export const listCorporations = async (
  params?: ListCorporationParams
): Promise<ListResponse<ListCorporationItem>> => {
  const { data } = await axios.get<ListResponse<ListCorporationItem>>("/api/corporations", {
    params,
  });
  return data;
};

export const listAllCorporations = listAllFactory(listCorporations);

export type DetailCorporationParams = {
  id: string;
};
type DetailCorporationItem = ListCorporationItem;
export const detailCorporation = async ({
  id,
}: DetailCorporationParams): Promise<DetailCorporationItem> => {
  const { data } = await axios.get<DetailCorporationItem>(`/api/corporations/${id}/`);
  return data;
};

export type CreateCorporationParams = {
  name: string;
};

export const createCorporation = async (
  params: CreateCorporationParams
): Promise<DetailCorporationItem> => {
  const { data } = await axios.post<DetailCorporationItem>(`/api/corporations/`, params);
  return data;
};
