import React from "react";
import { Route, Routes } from "react-router-dom";

import AppWrapper from "components/AppWrapper";
import ProtectedRoute from "components/ProtectedRoute";
import { useAuth } from "context/AuthProvider";
import SmappExportMain from "./main";


const SmappExport: React.FC = () => {
  const { isAdmin } = useAuth();

  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <AppWrapper mainSxProps={{ width: "100%", maxWidth: "100%" }}>
              <SmappExportMain />
            </AppWrapper>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default SmappExport;
