import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useAuth } from "context/AuthProvider";
import { ListCorporationItem, listCorporations } from "queries/corporations";
import { getDeviceStatusSummary } from "queries/devices";
import { useState, useCallback } from "react";
import {
  DeviceDetailedStatus,
  DeviceGeneralStatus,
  getDeviceStatusColor,
} from "utils/device-statuses";

const useDashboard = () => {
  const { currentUser } = useAuth();

  const [selectedCorporation, setSelectedCorporation] = useState<ListCorporationItem | null>(null);

  const { data: statusSummary, isLoading: isStatusLoading } = useQuery({
    queryKey: ["deviceStatusSummary", selectedCorporation],
    queryFn: () =>
      getDeviceStatusSummary({
        corporations__id: selectedCorporation?.id || undefined,
      }),
    enabled: true,
  });

  const generalStatusCounts = statusSummary?.general.reduce((acc, item) => {
    const { status_general: status, number } = item;
    acc[status as DeviceGeneralStatus] = (acc[status as DeviceGeneralStatus] || 0) + number;
    return acc;
  }, {} as Record<DeviceGeneralStatus, number>);

  const detailedStatusCounts = statusSummary?.detailed.reduce((acc, item) => {
    const { status_detailed: status, number } = item;
    acc[status] = (acc[status] || 0) + number;
    return acc;
  }, {} as Record<string, number>);

  const generalChartData = Object.entries(generalStatusCounts || {}).map(([status, count]) => ({
    status: status as DeviceGeneralStatus,
    count,
    color: getDeviceStatusColor(status as DeviceGeneralStatus, "general"),
  }));

  const detailedChartData = Object.entries(detailedStatusCounts || {}).map(([status, count]) => ({
    status: status as DeviceDetailedStatus,
    count,
    color: getDeviceStatusColor(status as DeviceDetailedStatus, "detailed"),
  }));

  const numberOfDevices = statusSummary?.total_devices || 0;

  const { isAdmin } = useAuth();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const PAGE_SIZE = 20;

  const fetchCorporations = useCallback(async (page: number, search: string) => {
    try {
      const response = await listCorporations({
        page,
        page_size: PAGE_SIZE,
        search,
      });

      const corporations = response.data;

      return {
        corporations,
        nextPage: response.meta.count > page * PAGE_SIZE ? page + 1 : null,
        total: response.meta.count,
      };
    } catch (error) {
      console.error("Error fetching corporations:", error);
      throw error;
    }
  }, []);

  const {
    data: corporationsPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["corporations", searchTerm] as const,
    queryFn: ({ pageParam = 1 }) => fetchCorporations(pageParam, searchTerm),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const corporations = {
    data: corporationsPages?.pages.flatMap((page) => page.corporations) ?? [],
  };

  const onCorporationChange = (_: React.SyntheticEvent, newValue: ListCorporationItem | null) => {
    setSelectedCorporation(newValue || null);
  };

  const handleInputChange = (_: any, newInputValue: string) => {
    setSearchTerm(newInputValue);
  };

  const getNameToDisplay = () => {
    if (!currentUser) {
      return "Back";
    }

    return currentUser.first_name.length === 0 ? currentUser!.username : currentUser.first_name;
  };

  return {
    generalChartData,
    detailedChartData,
    numberOfDevices,
    onCorporationChange,
    handleInputChange,
    firstName: getNameToDisplay(),
    isAdmin,
    corporations,
    selectedCorporation,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useDashboard;
