import React from "react";
import {
  Area,
  AreaChart,
  AreaProps,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export type ChartProps<TKey extends Exclude<string, "x">> = {
  chartConfig: (AreaProps & { dataKey: TKey })[];
  data: Record<string, Record<TKey, number | null | undefined>>;
  yAxisDomain?: number[];
};

const Chart = <TKey extends Exclude<string, "x">>({
  chartConfig,
  data: propData,
  yAxisDomain,
}: ChartProps<TKey>) => {
  const data = React.useMemo(
    () =>
      Object.entries(propData).map(([k, v]) => ({
        x: k,
        ...v,
      })),
    [propData]
  );
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ right: 32, top: 10, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
        <XAxis 
          dataKey="x" 
          tick={{ fill: '#666666' }}
          axisLine={{ stroke: '#E5E5E5' }}
        />
        {yAxisDomain ? (
          <YAxis 
            domain={yAxisDomain} 
            tick={{ fill: '#666666' }}
            axisLine={{ stroke: '#E5E5E5' }}
          />
        ) : (
          <YAxis 
            tick={{ fill: '#666666' }}
            axisLine={{ stroke: '#E5E5E5' }}
          />
        )}
        <Tooltip 
          contentStyle={{
            backgroundColor: 'rgba(39, 56, 44, 0.7)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '10px',
            color: '#ffffff'
          }}
          itemStyle={{
            color: '#ffffff'
          }}
          labelStyle={{
            color: '#ffffff'
          }}
        />
        {chartConfig.map((props) => (
          // @ts-expect-error recharts type error
          <Area 
            key={props.dataKey}
            {...props}
            strokeWidth={2}
            fillOpacity={0.3}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Chart;
