import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginMain from "pages/login/main";
import RegisterMain from "pages/register/main";
import HomeMain from "pages/home/main";
import ProtectedRoute from "components/ProtectedRoute";
import AnonymRoute from "components/AnonymRoute";
import Devices from "pages/devices";
import Dashboard from "pages/dashboard";
import ROUTES from "routes";
import AboutDialog from "components/AboutDialog";
import MyProfile from "pages/myProfile";
import Map from "pages/map";
import DevicesConfig from "pages/devicesConfig";
import { useAuth } from "context/AuthProvider";
import Firmwares from "pages/firmwares";
import VerticalMenuBar from "components/MenuBar/VerticalMenuBar";
import SmappExport from "pages/export";
import SmappManagement from "pages/management";
import Orcards from "pages/orchard";
import OrchardManager from "pages/orchard/manager/orchardManager";
import ValidatorDeviceList from "pages/validatorDevices";
import { USER_TYPE } from "queries/users";
import ValidatorManagement from "pages/validatorManagement";
import { Box } from "@mui/material";

const App: React.FC = () => {
  const { isLoggedIn, isAdmin, userType } = useAuth();

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <HomeMain />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.LOGIN}
          element={
            <AnonymRoute>
              <LoginMain />
            </AnonymRoute>
          }
        />
        <Route
          path={ROUTES.REGISTER}
          element={
            <AnonymRoute>
              <RegisterMain />
            </AnonymRoute>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <iframe
              src="/terms-and-conditions.html"
              style={{ width: "100%", height: "100vh", border: "none" }}
              title="Terms and Conditions"
            />
          }
        />
      </Routes>
    );
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F1EEEA" }}>
      <VerticalMenuBar userType={userType || USER_TYPE.REGULAR} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: "60px", sm: 0 }
        }}
      >
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={
              <AnonymRoute>
                <LoginMain />
              </AnonymRoute>
            }
          />
          <Route
            path={ROUTES.REGISTER}
            element={
              <AnonymRoute>
                <RegisterMain />
              </AnonymRoute>
            }
          />
          <Route
            path={`${ROUTES.DEVICES}/*`}
            element={
              <ProtectedRoute>
                <Devices />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.DASHBOARD}/*`}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.ORCHARD}/*`}
            element={
              <ProtectedRoute>
                <Orcards />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.MAP}/*`}
            element={
              <ProtectedRoute>
                <Map />
              </ProtectedRoute>
            }
          />
          {isAdmin && (
            <Route
              path={`${ROUTES.DEVICES_CONFIG}/*`}
              element={
                <ProtectedRoute>
                  <DevicesConfig />
                </ProtectedRoute>
              }
            />
          )}
          {isAdmin && (
            <Route
              path={`${ROUTES.FIRMWARES}/*`}
              element={
                <ProtectedRoute>
                  <Firmwares />
                </ProtectedRoute>
              }
            />
          )}
          {isAdmin && (
            <Route
              path={`${ROUTES.MANAGEMENT}/*`}
              element={
                <ProtectedRoute>
                  <SmappManagement />
                </ProtectedRoute>
              }
            />
          )}
          {isAdmin && (
            <Route
              path={`${ROUTES.VALIDATOR_MANAGEMENT}/*`}
              element={
                <ProtectedRoute>
                  <ValidatorManagement />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path={ROUTES.HOME}
            element={
              <ProtectedRoute>
                <HomeMain />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.MY_PROFILE}
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.ORCHARD_MANAGER}/*`}
            element={
              <ProtectedRoute>
                <OrchardManager />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.EXPORT}/*`}
            element={
              <ProtectedRoute>
                <SmappExport />
              </ProtectedRoute>
            }
          />
          {(isAdmin || userType === USER_TYPE.VALIDATOR) && (
            <Route
              path={`${ROUTES.VALIDATOR_DEVICES}/*`}
              element={
                <ProtectedRoute>
                  <ValidatorDeviceList />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path="/terms-and-conditions"
            element={
              <iframe
                src="/terms-and-conditions.html"
                style={{ width: "100%", height: "100vh", border: "none" }}
                title="Terms and Conditions"
              />
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <HomeMain />
              </ProtectedRoute>
            }
          />
        </Routes>
        <AboutDialog />
      </Box>
    </Box>
  );
};

export default App;
