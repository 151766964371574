import React, { useState } from "react";
import { Box, ListSubheader, Paper, Typography, Stack, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { statusToReadable } from "utils/device-statuses";
import DeviceStatusDonutChart, { DeviceStatusData } from "./DeviceStatusDonutChart";

interface Props {
  title?: string;
  data: DeviceStatusData[];
  numberOfDevices?: number;
}

const DeviceStatusDonutChartPanel: React.FC<Props> = ({
  title = "",
  data,
  numberOfDevices
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldShowExpandButton = data.length > 7;
  const sortedData = [...data].sort((a, b) => b.count - a.count);
  const visibleData = isExpanded ? sortedData : sortedData.slice(0, 7);

  return (
    <Paper
      sx={{
        pt: 1,
        pb: 2,
        px: 2,
        height: isExpanded ? "auto" : "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
        borderRadius: "24px",
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Box sx={{ mb: 1 }}>
        <ListSubheader sx={{ 
          flex: 1, 
          fontSize: "14px", 
          fontWeight: "bold", 
          color: "#27382C", 
          background: "transparent" 
        }}>{title}</ListSubheader>
      </Box>
      <Box sx={{
        height: "220px",
        position: "relative",
        flexShrink: 0,
        mb: 1,
      }}>
        <DeviceStatusDonutChart data={data} />
        {numberOfDevices !== undefined && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center'
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {numberOfDevices}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Devices
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Stack
          direction="row"
          spacing={2}
          overflow="hidden"
          sx={{
            mt: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {visibleData.map((item) => (
            <Box
              key={item.status}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: item.color,
                  borderRadius: 1
                }}
              />
              <Typography variant="caption">
                {statusToReadable(item.status)}
              </Typography>
            </Box>
          ))}
        </Stack>
        {shouldShowExpandButton && (
          <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}>
            <IconButton
              onClick={() => setIsExpanded(!isExpanded)}
              size="small"
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default DeviceStatusDonutChartPanel; 