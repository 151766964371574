import React from "react";
import { DEVICE_TYPE, DetailDeviceItem } from "queries/devices";
import { DetectionCountsByDevicesChartPanel } from "components/visualization/DetectionCountDeltaChartPanel";

type ChartPanelProps = {
  device: DetailDeviceItem;
};
const ChartPanel: React.FC<ChartPanelProps> = ({ device }) => (
  <>
    <DetectionCountsByDevicesChartPanel deviceID={device.id} aggregations="sum" />
    <DetectionCountsByDevicesChartPanel deviceID={device.id} aggregations="sum" frequency="week" />
  </>
);

export default ChartPanel;
